import React from "react"

import SEO from "../components/seo"
import Header from "../components/Header"
import Landing from "../components/Landing"
import Contact from "../components/Contact"
import Footer from "../components/Footer"

const IndexPage = () => {
  return (
    <>
      <SEO title="Hodges" path="/" />
      <Header />
      <Landing />
      <Contact />
    </>
  )
}

export default IndexPage
