import React from "react"
import Link from "../utils/link"

import { StaticImage } from "gatsby-plugin-image"

const Landing = () => {
  return (
    <div className="landing">
      <div className="landing__container">
        <div className="landing__title">
          WIN A <br /> BMW X1
          <div className="landing__title_underline">
            <StaticImage
              src="../assets/images/title_underline.png"
              alt="title underline"
            />
          </div>
        </div>
        <div className="landing__footer">
          <div className="landing__subtitle">
            Sell with Hodges and go into the draw to win a BMW X1 valued at
            $61,852*
          </div>
          <div className="landing__quote">
            <div className="landing__quote_title">
              The road to your dream moment starts{" "}
              <div className="landing__quote_underline-text">
                here
                <div className="landing__quote_title_underline">
                  <StaticImage
                    src="../assets/images/quote_underline.png"
                    alt="Quote Underline"
                  />
                </div>
              </div>
            </div>
            <div className="landing__quote_logo">
              <Link to="/">
                <StaticImage src="../assets/images/logo.png" alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
