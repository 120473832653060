import axios from "axios"
import React from "react"
import Link from "../utils/link"
import MailIcon from "../assets/images/mail.svg"

const Contact = () => {
  const [issubmitting, setissubmitting] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    date: "",
    terms: false,
  }
  const [formData, setFormData] = React.useState(initialFormData)
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleCheckBoxChange = (e) => {
    setFormData({ ...formData, terms: !formData.terms })
  }
  const submitForm = (e) => {
    e.preventDefault()
    if (issubmitting) return
    setissubmitting(true)
    const params = { ...formData }
    delete params.terms
    axios.post("https://formspree.io/f/xjvjjjvl", params).then((res) => {
      setissubmitting(false)
      setIsSubmitted(true)
      setFormData(initialFormData)
    })
  }
  return (
    <div className="contact">
      <div className="contact__container">
        <div className="contact__text">
          <div className="contact__text_title">
            {!isSubmitted ? (
              <div>
                Simply fill in your details and one of our property experts will
                help start your journey with a free property appraisal.
              </div>
            ) : null}
          </div>
          <div className="contact__text_terms">
            <div className="contact__text_subtitle">
              *Terms & Conditions apply. <br />
              Competition is open to permanent residents of Australia. <br />
              Entrants must be over 18 to enter. <br />
            </div>

            <div className="contact__text_links">
              <a
                target="_blank"
                href="https://www.hodges.com.au/bmw-competition-tc/"
              >
                Terms & Conditions
              </a>
              <a
                target="_blank"
                href="http://www.hodges.com.au/privacy-statement/"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        {isSubmitted ? (
          <div className="contact__thankyoumsg">
            <div className="contact__thankyoumsg__thanks">
              <div className="contact__thankyoumsg__thanks__icon">
                <img src={MailIcon} alt="" />
              </div>
              <div className="contact__thankyoumsg__thanks__text">
                Thank you
              </div>
            </div>
            <div className="contact__thankyoumsg_info">
              A member of the Hodges team will be in contact.
            </div>
          </div>
        ) : (
          <form onSubmit={submitForm} className="contact__form">
            <div className="contact__form_form-control">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                className="contact__form_form-control_input"
                onChange={handleInputChange}
                disabled={issubmitting}
                required
              />
            </div>
            <div className="contact__form_form-control">
              <input
                type="text"
                placeholder="Last Name*"
                value={formData.lastName}
                name="lastName"
                className="contact__form_form-control_input"
                onChange={handleInputChange}
                disabled={issubmitting}
                required
              />
            </div>
            <div className="contact__form_form-control">
              <input
                title="Enter a valid email"
                type="email"
                placeholder="Email Address*"
                name="email"
                value={formData.email}
                className="contact__form_form-control_input"
                onChange={handleInputChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                disabled={issubmitting}
                required
              />
            </div>
            <div className="contact__form_form-control">
              <input
                type="text"
                placeholder="Contact Number*"
                title="Your Phone Number eg: +61000 000 0000"
                value={formData.phone}
                name="phone"
                className="contact__form_form-control_input"
                onChange={handleInputChange}
                disabled={issubmitting}
                required
              />
            </div>
            <div className="contact__form_form-control">
              <input
                type="text"
                placeholder="Enter the address of the property*"
                name="address"
                value={formData.address}
                className="contact__form_form-control_input"
                onChange={handleInputChange}
                disabled={issubmitting}
                required
              />
            </div>
            <div className="contact__form_date">
              <div className="contact__form_date_title">
                Your preferred contact date*
              </div>
              <div className="contact__form_form-control">
                <input
                  type="date"
                  placeholder="DD / MM / 21"
                  name="date"
                  value={formData.date}
                  className="contact__form_form-control_input"
                  onChange={handleInputChange}
                  disabled={issubmitting}
                  required
                />
              </div>
            </div>
            <div className="contact__form_inputs">
              <div className="contact__form_inputs_confirm">
                <div>
                  <input
                    type="checkbox"
                    name="terms"
                    checked={formData.terms}
                    onChange={handleCheckBoxChange}
                    disabled={issubmitting}
                    required
                  />
                </div>
                <label className="contact__form_inputs_confirm_text">
                  I agree to the Hodges x BMW X1 Series Competition’s{" "}
                  <a
                    className="contact__form_inputs_confirm_text_terms"
                    target="_blank"
                    href="https://www.hodges.com.au/bmw-competition-tc/"
                  >
                    Terms & Conditions
                  </a>
                </label>
              </div>
              <button
                type="submit"
                className={
                  issubmitting
                    ? "contact__form_inputs_btn contact__form_inputs_btn_submitting "
                    : "contact__form_inputs_btn"
                }
              >
                Submit your details
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Contact
